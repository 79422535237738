import { serverSideTranslations } from "next-i18next/serverSideTranslations";
import { useRouter } from 'next/router'
import { useEffect } from "react";

export async function getStaticProps({ locale }) {
  return {
    props: {
      ...(await serverSideTranslations(locale)),
    },
  };
}

export default function PageNotFound({}) {
  const router = useRouter()

  useEffect(() => {
    setTimeout(() => {
      router.replace('/')
    }, 2000)
  }, [])

  return (
    <>
      <section>
        <div className="section-main md:flex-col-reverse md:flex relative py-[230px] lg:py-[150px] md:py-20 overflow-hidden">
          <img
            src="/images/404.svg"
            className="absolute top-0 right-0 max-w-[800px] xl:max-w-[700px] lg:max-w-[550px] md:static ms:max-w-full object-contain md:mx-auto md:block"
            alt="CyStack image page not found"
          />
          <div className="container text-dark relative z-[1]">
            <div className="max-w-[475px] text-dark text-body1">
              <h1 className="text-[100px] leading-[120px] mb-8">404</h1>
              <h1 className="mb-8">Page not found</h1>
              <p>This page seems to be lost in cyberspace.</p>
              <p>Sorry for the inconvenience, please try again.</p>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
